import React from "react";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";



import "./style.scss";

const Marquee = ({ text, speed, image }) => {
  return (
    <>
    <div className="marqueeContainer">
    <div className="columns">
        <div className="column is-10 is-offset-1">
          <h1 className="marquee">
            <span className={speed} aria-hidden="true">
              {text}
            </span>
            <span className={speed} aria-hidden="true">
              {text}
            </span>
            <span className={speed} aria-hidden="true">
              {text}
            </span>
            <span className={speed} aria-hidden="true">
              {text}
            </span>
            <span className={speed} aria-hidden="true">
              {text}
            </span>
          </h1>
          <Controller>
            <Scene offset={0} duration="50%" triggerHook="onEnter">
              <Timeline wrapper={<div className="parallax" />}>
                <Tween
                  position="0"
                  from={{
                    top: "100%",
                    scale: 0.5,
                  }}
                  to={{
                    top: "-50%",
                    scale: 1,
                  }}
                >
                  <img src={image} alt="" />                  
                </Tween>
              </Timeline>
            </Scene>
            <div className="section" />
          </Controller>
        </div>
      </div>
    </div>      
    </>
  );
};

export default Marquee;
