import React from "react";
import Marquee from "../components/marquee/marquee.js";
import Header from "../components/header/header.js";
import CategoryButtons from "../components/categoryButtons/categoryButtons";
import Spiral from "../assets/img/svg/spiral.svg";
import Profits from "../assets/img/svg/profits.svg";

const Marketing = () => {
  return (
    <>
      <Header
        title="Strumento di Marketing"
        tagTitle="Il Wifi è uno strumento di marketing"
        metaKeywords="marketing wifi"
        description={`È un prezioso strumento di marketing grazie al quale potrai
         raccogliere i dati profilati degli utenti che si connettono alla tua rete WiFi, 
         in modo da attivare campagne e strategie promozionali mirate per la tua attività.`}
        categoryIcon={{ icon: Spiral, alt: "Strumento di Marketing Banner" }}
        metaDescription="È un prezioso strumento di marketing grazie al quale potrai raccogliere i dati profilati degli utenti che si connettono alla tua rete WiFi"
      />
      <div className="container">
        <div className="columns">
          <div className="column is-7">
            <h2>
              Un potente sistema di Free WiFi con profilazione dell’utenza per
              orientare al meglio le vostre strategie di marketing.
            </h2>
            <p className="description">
              <br />
              <br />
              È un prezioso, potente ed efficiente strumento di Web e Viral
              Marketing grazie al quale, attraverso il monitoraggio della rete e
              delle relative connessioni, potrai raccogliere i dati profilati
              degli utenti, rivolgendoti ad un bacino d’utenza in costante
              aumento, in modo da attivare campagne e strategie promozionali
              mirate per la tua attività.
              <br />
              <br /> La piattaforma gratuita, la facilità del login e l’offerta
              del multi-lingua agisce da moltiplicatore per gli accessi e per i
              nuovi login garantendo una base di target profilata in costante
              crescita.
              <br />
              <br /> Grazie alle informazioni dettagliate sugli utenti e agli
              strumenti di analytics e sharing è possibile progettare campagne e
              strategie mirate; con un incremento di promozione sui social
              network potrai ottenere più visibilità (Social engage) grazie alle
              funzioni di social WiFi ottieni “mi piace” dagli utenti, raccogli
              i loro dati e promuovi la tua attività.
            </p>
          </div>
        </div>
      </div>

      <div className="section" />
      <div className="section" />
      <Marquee text="Incrementa i tuoi profitti" speed="slow" image={Profits} />
      <div className="section" />
      <div className="container">
        <div className="columns">
          <div className="column">
            <h2>Come può aiutarti Connect@You?</h2>
          </div>
          <div className="column">
            <p className="description">
              Creando il tuo database con i contatti dei clienti raccolti in
              fase di autenticazione (lead generation), tramite l’interfaccia di
              login, e-mail o account social, puoi sviluppare campagne di
              marketing verticali e mirate.
              <br />
              <br />
            </p>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <p className="description">
              La totale personalizzazione della landing page ti permette di
              effettuare azioni di Branding, promuovendo il tuo marchio e la tua
              attività, e di Bannering, creando azioni di upselling in modo da
              promuovere le attività di terzi e/o partner, i cosiddetti “walled
              garden”, ovvero spazi web dedicati alla navigazione senza
              necessità di autenticazione.
              <br />
              <br />
              Inoltre, hai la possibilità di scegliere su quale piattaforma far
              atterrare l’utente una volta autenticatosi. Il redirect potrà
              avvenire su profili social, business, welcome page dedicate.
            </p>
          </div>
        </div>
      </div>
      <div className="section" />
      <div className="columns">
        <div className="column is-half is-offset-one-quarter">
          <p className="center">Ti potrebbe interessare...</p>
        </div>
      </div>
      <div className="section" />
      <CategoryButtons titlePage="Strumento di marketing" />
    </>
  );
};

export default Marketing;
